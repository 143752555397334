import { Link } from "react-router-dom";
import logo from './img/logo.png';


function MainNavigationTemp() {
    return (
        <header>
            <nav className="theme12b">
                <Link to="/">
                    <img src={logo} alt="H-1B Canada Logo" width="150px" />
                </Link>
                <ul>               <li className="body2 header-right">Operated by authorized Canadian immigration professionals</li>
</ul>
            </nav>
        </header>
    )
}

export default MainNavigationTemp;