import { Link } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function FooterTemp() {
    return (
        <Row className="color-row-light-primary center-box mb-0 mt-0 pb-0 pt-0">
            <Col>
                <div className="body2">
                <Link to="/term">Term of Use</Link> | <Link to="/privacy">Privacy Policy </Link>
                </div>
                <div className="body2">
                © 2023 CICS Immigration Consulting Inc. All Rights Reserved.
                </div>
            </Col>
        </Row>
    )
}

export default FooterTemp;