import { Outlet } from "react-router-dom";
import MainNavigationTemp from "./MainNavigationTemp";
import FooterTemp from "./FooterTemp";
import Container from 'react-bootstrap/Container';

function RootLayoutTemp() {
    return (<>
        <Container className="mb-0 pb-0 theme12b">
            <MainNavigationTemp />
            <Outlet />
            <FooterTemp />
        </Container>
    </>);
}

export default RootLayoutTemp;
