import React, { useState } from 'react';


import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import img1 from './img/People.jpg';
import Accordion from 'react-bootstrap/Accordion';
import Image from 'react-bootstrap/Image';
import jsonp from 'jsonp'
import gtag from 'ga-gtag'

function Temp() {
  const [NWESLETTER_EMAIL, setNewsletterEmail] = useState("")


  function handleSubmit(e) {
    e.preventDefault();
    const email = NWESLETTER_EMAIL
    const url = 'https://h1b.us21.list-manage.com/subscribe/post-json?u=d98919f998edf1f4677bad58b&amp;id=0000c68c1c&amp;f_id=0002d9e6f0';
    jsonp(`${url}&EMAIL=${email}`, { param: 'c' }, (_, data) => {
      const { msg, result } = data
      // do something with response

      if (result === "success") {
        setNewsletterEmail("")
        alert(msg);
      } else {
        alert(msg);
      }
    });
  }

  const handleNewsletterEmail = (event) => {
    gtag('event', 'conversion', { 'send_to': 'AW-11262026750/3yP1CNfW4bwYEP7Hk_op' })
    setNewsletterEmail(event.target.value)
  };

  const openInNewTab = (url) => {
    gtag('event', 'event_test', {
      test_arg: 'something',
    })
    gtag('event', 'conversion', { 'send_to': 'AW-11262026750/3yP1CNfW4bwYEP7Hk_op' })
    window.open(url, "_blank", "noreferrer");
  };



  return (
    <>

      <Row className="center-box">
        <h1 className="mega">Are you a US H-1B holder?</h1>
        <h2 className="h2">Come to Canada with a 3-Year Open Permit!</h2>
        <h3 className="h3 orenge-text">The intake cap of 10,000 was reached. However, we will keep providing useful Canadian immigration topics, including programs other than the H-1B Open permit.</h3>


      </Row>

      <Row className="center-box">
        <Col lg={{ span: 6, offset: 3 }}>
          <h3 className="h3">Want more updates?</h3>
          <form className="row align-items-top justify-content-center" onSubmit={handleSubmit}>
            <div className="col-auto">
              <input type="email" className="form-control form-control-lg email-form" aria-describedby="emailHelp" value={NWESLETTER_EMAIL} placeholder="Enter email" onChange={handleNewsletterEmail} ></input>
            </div>
            <div className="col-auto">
              <Button variant="primary" type="submit">Subscribe to our newsletter</Button>
            </div>
          </form>      </Col>
      </Row>


      <Row className="center-box">
        <Col lg={{ span: 6, offset: 3 }}>
          <Image src={img1} alt="Canada" fluid />
        </Col>
      </Row>
      <Row className="plain-row">
        <Col lg={6}>
          <h2 className="h2">What do we know so far?</h2>
          <div className="body">
            IRCC announced a new open working permit program for US H-1B holders. The new program is available as of July 16, 2023.
          </div>

          <u><h3 className="h4 link-text" onClick={() => openInNewTab("https://www.canada.ca/en/immigration-refugees-citizenship/services/work-canada/permit/h1b.html")}>Program details are available here.</h3></u>

          <h3 className="h3">Want updates?</h3>
          <form className="row align-items-top justify-content-center" onSubmit={handleSubmit}>
            <div className="col-auto">
              <input type="email" className="form-control form-control-lg email-form" id="email" aria-describedby="emailHelp" value={NWESLETTER_EMAIL} placeholder="Enter email" onChange={handleNewsletterEmail} ></input>
            </div>
            <div className="col-auto">
              <Button variant="primary" type="submit">Subscribe to our newsletter</Button>
            </div>
          </form>
        </Col>

        <Col>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>What is the H-1B Canada Open Work Permit?</Accordion.Header>
              <Accordion.Body>
                This new pathway will allow H-1B Specialty Occupations visa holders to work for almost any company in Canada for 3 years.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Who is eligible?</Accordion.Header>
              <Accordion.Body>
                Anyone with a H-1B Visa, currently residing in the U.S. There could be more information released later.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Can I bring my family with me?</Accordion.Header>
              <Accordion.Body>
                Yes. Families can accompany the principal applicant in this application. The spouse can receive an open work permit and the children can receive a study or visitor visa.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header> Can I get permanent residency (green card) with this work visa?</Accordion.Header>
              <Accordion.Body>
                By gaining work experience in Canada, your chances of being able to reach permanent residency increases.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>What are the advantages of this work permit?</Accordion.Header>
              <Accordion.Body>
                Having an open work permit allows you to work for any Canadian company, without them needing to sponsor you.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>Will immigrating to Canada give an advantage to immigrating to the U.S. in the future?</Accordion.Header>
              <Accordion.Body>
                It can. The U.S. has established specific visa categories to facilitate Canadian immigration; For instance, the TN Visa. Canada is also part of the Visa Waiver Program, and as the U.S. uses a system of country-based quotas for some of its visas, the processing times for applications coming from Canada are normally faster.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

        </Col>
      </Row>
      <Row className="color-row-light-primary pb-5">
        <Col>
          <h2 className="h2">Who are we?</h2>

          <div className="body">
            Led by Dr. Mo H. Khadempour and Alex Khadempour, CICS Immigration Consulting Inc. has been a trusted Canadian company and has served thousands of businesses and individuals through numerous changes in the immigration regulations.
            We are authorized by Citizenship and Immigration Canada, as Canadian Immigration Consultants, to consult on, and represent applicants in all 60+ immigration pathways in Canada.
          </div>
          <Button variant="primary" role="link" onClick={() => openInNewTab("https://www.cicsimmigration.com/")}>Check our website</Button>{' '}
        </Col>
      </Row>



    </>
  );
}

export default Temp;