import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Privacy() {

  return (
    <>
      <Row className="plain-row">
        <Col>
          <h1 className="h2">Privacy Policy</h1>
          <div className="legal">
<p>Effective date: 2023-07-15</p>
<p>Updated on: 2023-07-15</p>
<p>
  This Privacy Policy explains the policies of CICS Immigration Inc. on the collection 
and use of the information we collect when you access https://h1b.ca (the “Service”). This Privacy Policy describes your 
privacy rights and how you are protected under privacy laws.</p>
<p>
  By using our Service, you are consenting to the collection and use of your information in accordance
    with this Privacy Policy. Please do not access or use our Service if you do not consent to the collection
    and use of your information as outlined in this Privacy Policy. This Privacy Policy has been created with
    the help of <a target="_blank" href="https://cookie-script.com/privacy-policy-generator">CookieScript Privacy Policy Generator</a>.</p>
<p>CICS Immigration Inc. is authorized to modify this Privacy Policy at any time.
This may occur without prior notice.</p>
<p>
  CICS Immigration Inc. will post the revised Privacy Policy on the https://h1b.ca website</p>

    <h3>Collection and Use of Your Personal Information</h3>
    <h4>Information We Collect</h4>
    <p>
      When using our Service, you will be prompted to provide us with personal information used to
contact or identify you. https://h1b.ca collects the following information:    </p>

    <ul>
                    <li>Usage Data</li>
                    <li>Name</li>
                    <li>Email</li>
                    <li>Payment Information</li>
                    <li>Mobile Number</li>
                    <li>Social Media Profile</li>
                    <li>Date of Birth</li>
                    <li>Work Address</li>
                    <li>Home Address</li>
            </ul>

            <p>Usage Data includes the following:</p>
        <ul>
            <li>Internet Protocol (IP) address of computers accessing the site</li>
            <li>Web page requests</li>
            <li>Referring web pages</li>
            <li>Browser used to access site</li>
            <li>Time and date of access</li>
        </ul>
    
    <h4>How We Collect Information</h4>
    <p>
      https://h1b.ca collects and receives information from you in the following manner:    </p>
    <ul>
                <li>When you fill a registration form or otherwise submit your personal information.</li>
                <li>When you interact with our Service.</li>
          </ul>
    <p>
      Your information will be stored for up to 365 days after it is
no longer required to provide you the services. Your information may be retained for longer periods for
reporting or record- keeping in accordance with applicable laws. Information which does not identify you
personally may be stored indefinitely.    </p>

            <h4>How We Use Your Information</h4>
        <p>
          https://h1b.ca may use your information for the following purposes:        </p>
        <ul>
                        <li><b>Providing and maintaining our Service,</b> as well as monitoring the usage of our Service.</li>
                        <li><b>For other purposes.</b> CICS Immigration Inc. will use your information for data analysis to identify usage
trends or determine the effective of our marketing campaigns when reasonable. We will use
your information to evaluate and improve our Service, products, services, and marketing efforts.</li>
                        <li><b>Managing your account.</b> Your Personal Data can enable access to multiple functions of our
Service that are available to registered users.</li>
                        <li><b>For the performance of a contract.</b> Your Personal Data will assist with the
development, undertaking, and compliance of a purchase contract for products or services you
have purchased through our Service.</li>
                        <li><b>To contact you.</b> CICS Immigration Inc. will contact you by email, phone, SMS, or another form of
electronic communication related to the functions, products, services, or security updates when
necessary or reasonable.</li>
                        <li><b>To update you</b> with news, general information, special offers, new services, and events.</li>
                        <li><b>Marketing and promotional initiatives.</b> CICS Immigration Inc. will use non-specific information gathered
from you in order to improve our marketing efforts.</li>
                        <li><b>Targeted advertising.</b> Your Personal Data will be used to improve advertising efforts that are
relevant to your specific interests.</li>
                        <li><b>Testimonials and customer feedback collection.</b> If you share a testimonial or review about your
experience using our Service, it will be shared or otherwise used on the website.</li>
                        <li><b>Managing customer orders.</b> Your email address, phone number, social media profiles, and other
user account information will be used in order to manage orders placed through our Service.</li>
                        <li><b>Administration information.</b> Your Personal Data will be used as part of the operation of our
website Administration practices.</li>
                        <li><b>Dispute resolution and site protection.</b> Your information will be used in the instance of a legal
dispute to resolve issues related to our Service.</li>
                  </ul>
    
            <h4>How We Share Your Information</h4>
        <p>
          CICS Immigration Inc. will share your information, when applicable, in the following situations:        </p>
        <ul>
                      <li><b>With your consent.</b> CICS Immigration Inc. will share your information for any purpose with your explicit
consent.</li>
                  </ul>
    
    <h4>Third-party Sharing</h4>
            <p>
          Any third party we share your information with must disclose the purpose for which they intend to use
your information. They must retain your information only for the duration disclosed when requesting or
receiving said information. The third-party service provider must not further collect, sell, or use your
personal information except as necessary to perform the specified purpose.        </p>
        <p>Your information may be shared to a third-party for reasons including:</p>
        <ul>
                      <li><b>Analytics information.</b> Your information might be shared with online
analytics tools in order to track and analyse website traffic.</li>
                      <li><b>Payment processing and recovery services.</b> Your information will be used in order to process payments in the 
event of a purchase, refund, or other similar request.</li>
                      <li><b>Marketing initiatives.</b> Your information will be used for generating and sending newsletters, email marketing
efforts, advertisements, and more.</li>
                      <li><b>Improving targeted advertising campaigns.</b> CICS Immigration Inc. partners with third party service providers to improve
targeted advertising campaigns.</li>
                      <li><b>Improving our Service.</b> Your information might be shared with third-party service providers in order to improve
our Service and/or interactions with providers.</li>
                  </ul>
        <p>
          If you choose to provide such information during registration or 
otherwise, you are giving CICS Immigration Inc. permission to use, share, and store that information in a manner consistent with 
this Privacy Policy.        </p>
    
    <p>Your information may be disclosed for additional reasons, including:</p>
    <ul>
        <li>Complying with applicable laws, regulations, or court orders.</li>
        <li>Responding to claims that your use of our Service violates third-party rights.</li>
        <li>Enforcing agreements you make with us, including this Privacy Policy.</li>
    </ul>

    <h4>Cookies</h4>
    <p>
        Cookies are small text files that are placed on your computer by websites that you visit. Websites use
cookies to help users navigate efficiently and perform certain functions. Cookies that are required for
the website to operate properly are allowed to be set without your permission. All other cookies need
to be approved before they can be set in the browser.    </p>
    <ul>
              <li><b>Strictly necessary cookies.</b> Strictly necessary cookies allow core website functionality such as
user login and account management. The website cannot be used properly without strictly necessary cookies.</li>
              <li><b>Performance cookies.</b> Performance cookies are used to see how visitors use the website, eg.
analytics cookies. Those cookies cannot be used to directly identify a certain visitor.</li>
              <li><b>Targeting cookies.</b> Targeting cookies are used to identify visitors between different websites, eg. content partners, banner networks. Those cookies may be used by companies to build a profile
of visitor interests or show relevant ads on other websites.</li>
              <li><b>Functionality cookies.</b> Functionality cookies are used to remember visitor information on the
website, eg. language, timezone, enhanced content.</li>
              <li><b>Unclassified cookies.</b> Unclassified cookies are cookies that do not belong to any other category
or are in the process of categorization.</li>
          </ul>

            <div class="cookie-report-container">
            <p data-cookiereport="true">
                You can change your consent to cookie usage below.            </p>
            <script
                type="text/javascript"
                charset="UTF-8"
                data-cookiescriptreport="report"
                data-cs-lang="en"
                src="//report.cookie-script.com/r/dda0d03a014e1969742306a8738a7f97.js"
            >
            </script>
        </div>
    
    <h4>Security</h4>
    <p>
      Your information’s security is important to us. https://h1b.ca 
utilizes a range of security measures to prevent the misuse, loss, or alteration of the information you have given us. 
However, because we cannot guarantee the security of the information you provide us, you must access our service at your
own risk.    </p>
    <p>
      CICS Immigration Inc. is not responsible for the performance of websites 
operated by third parties or your interactions with them. When you leave this website, we recommend you review the 
privacy practices of other websites you interact with and determine the adequacy of those practices.    </p>

    <h4>Contact Us</h4>
    <p>For any questions, please contact us through the following methods:</p>
    <p>Name: CICS Immigration Inc.</p>
    <p>Address: 997 Seymour St #250, Vancouver, BC V6B 3M1</p>
    <p>Email: info@h1b.ca</p>
    <p>Website: https://h1b.ca</p>
            <p>Phone: +1-888-502-9525</p>
    

    

          </div>
        </Col>
      </Row >

    </>
  );
}

export default Privacy;