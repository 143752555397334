import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Helmet } from "react-helmet"

import Temp from './Temp';
import Term from './Term';
import Privacy from './Privacy';
import Construction from './Construction';
import RootLayoutTemp from './RootTemp';
import './App.css';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayoutTemp />,
    children: [
      { path: '/privacy', element: <Privacy /> },
      { path: '/term', element: <Term /> },
      { path: '/temp', element: <Temp /> },
      { path: '/', element: <Temp /> },
    ]
  },
]);

function App() {
  return <>
        <Helmet>
        <title>H-1B Visa Canada: Operated by authorized Canadian immigration professionals</title>
      </Helmet>
    <RouterProvider router={router} />
  </>
}

export default App;



